import * as React from "react"
import Wrapper from "../components/wrapper"
import Header from "../components/header"
import Footer from "../components/footer"

import share from "../images/bookmarks-share.jpg"

import notion from "../images/bookmarks/notion.png"
import bear from "../images/bookmarks/bear.png"
import things from "../images/bookmarks/things.png"
import spark from "../images/bookmarks/spark.png"
import figma from "../images/bookmarks/figma.png"
import around from "../images/bookmarks/around.png"
import spotify from "../images/bookmarks/spotify.png"
import strava from "../images/bookmarks/strava.png"
import publicApp from "../images/bookmarks/public.png"

import creativeMarket from "../images/bookmarks/creative-market.png"
import ui8 from "../images/bookmarks/ui8.png"
import craftwork from "../images/bookmarks/craftwork.png"
import interfacer from "../images/bookmarks/interfacer.png"

import lostType from "../images/bookmarks/lost-type.png"
import fontJoy from "../images/bookmarks/font-joy.png"
import fontsmith from "../images/bookmarks/fontsmith.png"
import futureFonts from "../images/bookmarks/future-fonts.png"
import typefaceDirectory from "../images/bookmarks/typeface-directory.png"
import typewolf from "../images/bookmarks/typewolf.png"

import flowkit from "../images/bookmarks/flowkit.png"
import mockuuups from "../images/bookmarks/mockuuups.png"
import designkit from "../images/bookmarks/designkit.png"

import coolors from "../images/bookmarks/coolors.png"
import grabient from "../images/bookmarks/grabient.png"
import colorslurp from "../images/bookmarks/colorslurp.png"

import theNounProject from "../images/bookmarks/the-noun-project.png"
import myicons from "../images/bookmarks/myicons.png"
import streamlineIcons from "../images/bookmarks/streamline-icons.png"

import unsplash from "../images/bookmarks/unsplash.png"
import stocksy from "../images/bookmarks/stocksy.png"
import pexels from "../images/bookmarks/pexels.png"

import rora from "../images/bookmarks/rora.png"
import reallyGoodEmails from "../images/bookmarks/really-good-emails.png"

import removeBg from "../images/bookmarks/remove-bg.png"
import haikei from "../images/bookmarks/haikei.png"
import principle from "../images/bookmarks/principle.png"

const BookmarksPage = () => {
  const apps = [
    { name: "Notion", description: "Planning, Bookmarks, Wiki", url: "https://notion.so" },
    { name: "Bear", description: "Quick notes synced across devices", url: "https://bear.app", image: bear, color: "rgba(222, 60, 55, 0.08)" },
    { name: "Things", description: "Weekly & daily todos", url: "https://culturedcode.com/things/", image: things, color: "rgba(84, 150, 254, 0.08)" },
    { name: "Spark", description: "Email client for MacOS and iOS", url: "https://sparkmailapp.com", image: spark, color: "rgba(20, 129, 222, 0.08)" },
    { name: "Figma", description: "The best design tool", url: "https://figma.com", image: figma, color: "rgba(162, 89, 255, 0.08)" },
    { name: "Around", description: "Video calls", url: "https://www.around.co", image: around, color: "rgba(252, 118, 71, 0.08)" },
    { name: "Spotify", description: "Vibes", url: "https://www.spotify.com", image: spotify, color: "rgba(30, 215, 96, 0.08)" },
    { name: "Strava", description: "Track bike rides", url: "https://www.strava.com", image: strava, color: "rgba(252, 107, 38, 0.08)" },
    { name: "Public", description: "Investing gone social", url: "https://public.com", image: publicApp, color: "rgba(0, 0, 255, 0.06)" },
  ]

  const resourceCategories = [
    "Assets", "Fonts", "Libraries", "Colors", "Icons", "Stock imagery", "Inspiration", "Tools"
  ]

  const resources = [
    { name: "Creative Market", description: "Graphics, fonts, and templates", url: "https://creativemarket.com", category: "Assets", image: creativeMarket, color: "rgba(56, 180, 172, 0.08)" },
    { name: "UI8", description: "Trendy UI kits", url: "https://ui8.net", category: "Assets", image: ui8, color: "rgba(47, 139, 230, 0.08)" },
    { name: "Craftwork.design", description: "Illustrations and UI w/ freebies", url: "https://craftwork.design", category: "Assets", image: craftwork },
    { name: "Interfacer.xyz", description: "Free for commercial use", url: "https://interfacer.xyz", category: "Assets", image: interfacer, color: "rgba(38, 33, 255, 0.08)" },

    { name: "typeface.directory", description: "A curated list of beautiful typefaces", url: "https://typeface.directory", category: "Fonts", image: typefaceDirectory, color: "rgba(224, 219, 207, 0.2)" },
    { name: "Lost Type", description: "Collection of unique fonts", url: "https://www.losttype.com", category: "Fonts", image: lostType, color: "rgba(217, 217, 217, 0.2)" },
    { name: "Font Joy", description: "Generate font pairings in one click", url: "https://fontjoy.com", category: "Fonts", image: fontJoy, color: "rgba(3, 25, 237, 0.08)" },
    { name: "Future Fonts", description: "Experimental fonts", url: "https://www.futurefonts.xyz", category: "Fonts", image: futureFonts, color: "rgba(247, 11, 62, 0.04)" },
    { name: "Fontsmith", description: "Modern fonts", url: "https://www.fontsmith.com", category: "Fonts", image: fontsmith, color: "rgba(17, 24, 32, 0.04)" },
    { name: "Type Wolf", description: "Curated font lists and pairings", url: "https://www.typewolf.com", category: "Fonts", image: typewolf, color: "rgba(229, 221, 222, 0.3)" },

    { name: "FlowKit", description: "User flows", url: "https://useflowkit.com", category: "Libraries", image: flowkit, color: "rgba(123, 97, 255, 0.08)" },
    { name: "Mockuuups", description: "Device mockups", url: "https://mockuuups.studio", category: "Libraries", image: mockuuups, color: "rgba(16, 85, 251, 0.08)" },
    { name: "DesignKit", description: "Website wireframe kit", url: "https://rafaltomal.com/designkit/", category: "Libraries", image: designkit, color: "rgba(0, 0, 0, 0.03)" },

    { name: "Coolors", description: "Color scheme generator", url: "https://coolors.co", category: "Colors", image: coolors, color: "rgba(0, 188, 252, 0.08)" },
    { name: "Grabient", description: "Some nice gradients", url: "https://www.grabient.com", category: "Colors", image: grabient, color: " rgba(200, 80, 192, 0.08)" },
    { name: "ColorSlurp", description: "Color picker for MacOS", url: "https://colorslurp.com", category: "Colors", image: colorslurp, color: "rgba(37, 232, 56, 0.08)" },

    { name: "Streamline Icons", description: "100k+ icons", url: "https://streamlinehq.com", category: "Icons", image: streamlineIcons, color: "rgba(0, 246, 246, 0.08)" },
    { name: "The Noun Project", description: "Purchase individual icons", url: "https://thenounproject.com", category: "Icons", image: theNounProject },
    { name: "Myicons", description: "9.5k line icons ", url: "https://myicons.co", category: "Icons", image: myicons, color: "rgba(255, 221, 45, 0.1)" },

    { name: "Unsplash", description: "The best place for images", url: "https://unsplash.com", category: "Stock imagery", image: unsplash },
    { name: "Stocksy", description: "Premium stock images", url: "https://www.stocksy.com", category: "Stock imagery", image: stocksy },
    { name: "Pexels", description: "Free videos", url: "https://www.pexels.com", category: "Stock imagery", image: pexels, color: "rgba(5, 160, 129, 0.08)" },

    { name: "Rora", description: "Top things in design", url: "https://rora.co", category: "Inspiration", image: rora, color: "rgba(0, 0, 0, 0.05)" },
    { name: "Really Good Emails", description: "Catalog of real emails", url: "https://reallygoodemails.com", category: "Inspiration", image: reallyGoodEmails, color: "rgba(255, 76, 68, 0.08)" },

    { name: "Remove BG", description: "Remove backgrounds from images", url: "https://www.remove.bg", category: "Tools", image: removeBg, color: "rgba(84, 97, 108, 0.08)" },
    { name: "Haikei", description: "Generate unique SVGs", url: "https://haikei.app", category: "Tools", image: haikei, color: "rgba(139, 122, 244, 0.08)" },
    { name: "Principle", description: "Interaction prototypes", url: "https://principleformac.com", category: "Tools", image: principle, color: "rgba(133, 66, 184, 0.1)" },
  ]

  return (
    <Wrapper
      title="Bookmarks – Resources for designers"
      description="A list of my favorite apps and design resources."
      path="/bookmarks"
      shareImage={share}
    >
      <Header className="bg-yellow text-black" title="Bookmarks" />
      <div className="container">
        <h2 className="text-lg md:text-xl mt-20 mb-10">Favorite apps</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-6 mb-24">
          {apps.map((b, i) => (
            <Bookmark key={i} bookmark={b} />
          ))}
        </div>
        <h2 className="text-lg md:text-xl mb-10">Design resources</h2>
        {resourceCategories.map((c) => (
          <>
            <h4 className="font-semibold text-gray-500 text-sm tracking-tight mb-6">{c}</h4>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-6 mb-16">
              {resources.map((b, i) => b.category === c && <Bookmark key={i} bookmark={b} />)}
            </div>
          </>
        ))}
      </div>
      <Footer />
    </Wrapper>
  )
}

export default BookmarksPage

const Bookmark = ({ bookmark }) => {
  const background = bookmark.color || "rgba(0, 0, 0, 0.05)"
  return(
    <a href={bookmark.url || "#"} target="_blank" className="flex items-center group no-underline transition ease-in-out duration-200">
      <div className="p-2 mr-6 rounded-lg" style={{ background: background }}>
        <img src={bookmark.image || notion} className="w-16 h-16" />
      </div>
      <div>
        <h4 className="font-medium text-sm text-gray-800 group-hover:text-black">{bookmark.name}</h4>
        <p className="font-normal text-gray-600">{bookmark.description}</p>
      </div>
    </a>
  )
}
